import { Component } from "react";
import { withRouter } from './withRouter';

class Selectlanguage extends Component{
    constructor(props){
        super(props);
        this.navigate=this.navigate.bind(this);
    }
    navigate(val)
    {

        this.props.navigate('/'+val);
    }
    changeLanguage = (evt) =>{
        this.props.language(evt.target.value);
        this.navigate(evt.target.value);
        evt.preventDefault();
        //console.log(this.props.language);
       
    }
    render(){
        return(
            <div>
            <select className="form-select select-idioma" onChange= {this.changeLanguage}>
                <option value="es" >Spanish</option>
                <option value="en" >English</option>
                <option value="pt" >Portugues</option>
            </select>
            </div>
        );
    }

}

export default withRouter(Selectlanguage);