// src/i18n/config.js
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n.use(Backend).use(initReactI18next).init({
    fallbackLng: 'es',
    lng: 'es',
    resources: {
        es: {
            translations: require('./locales/es/characters.json')
        },
        en: {
            translations: require('./locales/en/characters.json')
        },
        pt: {
            translations: require('./locales/pt/characters.json')
        }
    },
    ns: ['translations'],
    defaultNS: 'translations'
});
i18n.languages = ['es','en','pt'];
export default i18n;