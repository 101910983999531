
import {Component} from 'react'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './Home';
import FormDonation from './donation/Form';

class App extends Component{
  render(){
    return(
      <div>
          <BrowserRouter >     
          <Routes>
              <Route path="/" element={<Home/>} />       
              <Route path="/donation" element={<FormDonation/>} />           
              <Route path="/es" element={<Home/>} /> 
              <Route path="/en" element={<Home/>} /> 
              <Route path="/pt" element={<Home/>} /> 
          </Routes>         
          </BrowserRouter>
      </div>
    );
  }
}

export default App;
