import {Component} from 'react';
class BotonDonation extends Component{
    render(){
    return(
        <div>          
            <button className="paypal">
                <a className="paypal myButton0a" href="/donation" target='_blank'><small>Donar a <span className="paypal1">Pay</span><span className="paypal2">Pal</span> para seguir el desarrollo y las traducciones </small></a>
            </button>

            <form action="https://www.paypal.com/donate" method="post" target="_top">
                <input type="hidden" name="business" value="donations@kcparkfriends.org"/>              
                <input type="hidden" name="no_recurring" value="0"/>
                <input type="hidden" name="item_name" value="Friends of the Park"/>
                <input type="hidden" name="item_number" value="Fall Cleanup Campaign"/>
                <input type="hidden" name="currency_code" value="USD"/>        
                <button className="myButton0a" type="image" name="submit" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" alt="Donate"/>
                <img className="myButton0a" alt="" width="1" height="1" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" />  
            </form>         
            
        </div>
        );
    }
}
export default BotonDonation;